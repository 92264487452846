<template>
    <section class="login_page toolbar_main">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 mx-auto">
                    <div class="widget">
                        <div class="login-modal-right">
                            <div class="tab-content">
                                <div class="tab-pane active" role="tabpanel">
                                    <h5 class="heading-design-h5"> <i class="fa fa-lock"></i>  Recuperar cuenta </h5>

                                    <p class="pb-1 mb-0" style=" font-weight: initial;line-height: normal; text-align:justify;">
                                        Selecciona tu tipo de cliente (<i>NATURAL</i>) e ingresa tu número de identificación (<i>DNI</i>) y  te enviaremos las instrucciones para una nueva contraseña.
                                    </p>
                                    <fieldset class="form-group">
                                        <strong class="required">Identificación</strong>
                                        <div class="input-group">
                                            <select class="form-control" v-model="formAccount.tipo_cliente" @change="cambiar_tipo_documento_cliente">
                                                <option v-for="(val, key) in tipo_cliente" :key="key" :value="val.identificador">{{val.descripcion}}</option>
                                            </select>
                                            <input type="text" :class="['form-control', (formAccount.numero_identificacion=='')?'border-danger':'']" v-model="formAccount.numero_identificacion" ref="numero_identificacion_registro" :maxlength="formAccount.long_tipo_doc_cliente" @keypress="isNumberInteger($event)" :placeholder="(formAccount.tipo_documento_cliente=='DNI')?'00000001':'10000000010'">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">{{formAccount.tipo_documento_cliente}}</span>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset v-if="input_email" class="form-group">
                                        <strong class="required">Email</strong>
                                        <input type="email" :class="['form-control', (formAccount.email=='')?'border-danger':'']" v-model="formAccount.email" ref="email_registro" placeholder="micuenta@example.com" @keypress="isEmail($event)">
                                        <span class="custom-control-description mt-0">
                                            Las instrucciones se enviarán al correo electrónico <b>{{formAccount.email}}</b>
                                        </span>
                                    </fieldset>
                                    
                                    <fieldset class="form-group">
                                        <button type="button" class="btn btn-lg btn-theme-round cursor-pointer btn-block" @click="recuperar_cuenta">Restablecer Cuenta</button>
                                    </fieldset>

                                    <fieldset class="form-group" v-if="errorSolicitud.length>0">
                                        <ul class="list-group">
                                            <li class="list-group-item list-group-item-action list-group-item-danger" v-for="(val, key) in errorSolicitud" :key="key">
                                                {{val}}
                                            </li>
                                        </ul>
                                    </fieldset>

                                     <fieldset class="form-group mb-0">
                                        <div class="alert mt-0 p-2 mb-0 alert-success" v-if="formAccount.msgSuccess!=''">
                                            {{formAccount.msgSuccess}}
                                            <p class="font-14 mb-0" >
                                                La página se recargará dentro de {{secondsReload}} segundos.
                                            </p>
                                        </div>

                                        <div class="alert mt-0 p-2 mb-0 alert-info">
                                            <p class="font-14 mb-0">
                                                Haga clic <a :href="'/tienda/'+sucursal_seleccionada">aquí</a> para ir al inicio <i class="icofont icofont-ui-home"></i>
                                            </p>
                                        </div>
                                     </fieldset>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    export default {
        name: 'recuperarClave',
        computed:{
            ...mapState(['empresa', 'sucursal_seleccionada']),
        },
        created(){
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
        },
        mounted(){
            this.getTipoCliente();
            document.title = "Recuperar Cuenta | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                formAccount:{
                    tipo_cliente:"N",
                    numero_identificacion:"",
                    long_tipo_doc_cliente:8,
                    tipo_documento_cliente:"DNI",
                    msgSuccess:"",
                    email:'',
                },
                errorSolicitud:[],
                tipo_cliente:[],
                secondsReload: 10,
                input_email:false,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada']),
            getTipoCliente(){
                this.$http.get('tiendaVirtual/get_tipo_cliente').then((res)=>{
                    this.tipo_cliente = res.data.data;
                }).catch(e => {
                    console.log(e.response);
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            cambiar_tipo_documento_cliente(){
                if(this.formAccount.tipo_cliente=="N"){
                    this.formAccount.tipo_documento_cliente = "DNI";
                    this.formAccount.long_tipo_doc_cliente  = 8;
                }else{
                    this.formAccount.tipo_documento_cliente = "RUC";
                    this.formAccount.long_tipo_doc_cliente  = 11;
                }
            },
            recuperar_cuenta(){
                if(this.input_email){
                    if(this.formAccount.email==""){
                        this.$refs.email_registro.focus();
                        return;
                    }
                }
                let selfs = this;
                let formData = new FormData();
                    formData.append("numero_identificacion", this.formAccount.numero_identificacion);
                    formData.append("tipo_cliente", this.formAccount.tipo_cliente);
                    formData.append("email", this.formAccount.email);
                
                selfs.setLoading(true);
                selfs.$http.post('tiendaVirtual/recuperar_cuenta', formData).then((res)=>{
                    selfs.input_email=false;
                    if(res.data.status){
                        selfs.errorSolicitud         = [];
                        selfs.formAccount.msgSuccess = res.data.msg;
                        let time_seconds = this.secondsReload*1000;

                        setInterval(()=>{
                            time_seconds = time_seconds - 1000;
                            this.secondsReload = time_seconds/1000;
                        }, 1000);

                        setTimeout(()=>{                            
                            location.reload();
                        }, time_seconds);
                    }
                }).catch(function (e) {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        selfs.errorSolicitud = [];
                        selfs.input_email=false;
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];
                            selfs.errorSolicitud.push(mapErrors[field]);

                            if(mapErrors[field]=="El DNI ingresado no tiene asignado un correo electrónico." || mapErrors[field]=="Correo electrónico ya está en uso, por favor ingrese otro."){
                                selfs.input_email=true;
                            }else{}
                        }
                    }
                }).finally(()=>{
                    selfs.setLoading(false);
                });
                /**/
            },
        }
    }
</script>
